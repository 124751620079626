.ferris-wheel-techs {
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.service-div {
  background-color: green;
  height: 100vh;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


